import React, { Component } from "react";
import cometchatLogo from "../../resources/images/cometchat_white.png";
import ironman from "../../resources/images/ironman@2x.png";
import captainamerica from "../../resources/images/captainamerica@2x.png";
import spiderman from "../../resources/images/spiderman@2x.png";
import wolverine from "../../resources/images/wolverine@2x.png";
import loader from "../../resources/images/loading1.gif";
import DemoUser from "./DemoUser";

function gup( name ) {
    var url=window.location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
}

class LoginForm extends Component {
  componentDidMount() {
    if (this.props.uid !== "") window.location = "/#/chat";
  }
   state = {
        userid: gup('userid'),
        username : gup('username')
    };


    


  render() {
    const userstate = this.state;


    return (
      <React.Fragment>
        <div  className="background-loader border-0 login-form-box bg-white px-5 py-5 col-lg-6 col-sm-12 col-md-9 col-xs-12">
          <form className="">



            <div>
            <div className="loader"></div>
              <DemoUser
                name={userstate.username}
                avatar="https://nimad.designpythons.com/wp-content/uploads/2019/10/sec2img.png"
                uid={userstate.userid}
                margin="mr-2"
                handleDemoLogin={this.props.handleDemoLogin}
              />
               
             
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginForm;
